import { Toast } from '@components/Toast';

window.addEventListener('unhandledrejection', (event) => {
  const error = event.reason;
  if (error.message && !error.ignore) {
    Toast.error(error.message);
  }
});

window.addEventListener('error', (event) => {
  const { error } = event;
  const msg = error.message;
  if (msg && !error.ignore) {
    Toast.error(msg);
  }
});
