import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

const QRLogin = lazy(() => import('@pages/Login/QRLogin'));
const InputMobile = lazy(() => import('@pages/Login/InputMobile'));
const InputPin = lazy(() => import('@pages/Login/InputPIN'));
const LoginInterim = lazy(() => import('@pages/Login/LoginInterim'));

export default createBrowserRouter([
  {
    path: '/qr-login',
    element: <QRLogin />,
  },
  {
    path: '/input-mobile',
    element: <InputMobile />,
  },
  {
    path: '/input-pin',
    element: <InputPin />,
  },
  {
    path: '/login-interim',
    element: <LoginInterim />,
  },
]);
