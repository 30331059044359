import { createRoot } from 'react-dom/client';
import { detectBrowser } from '@utils/browser';
import classNames from 'classnames';
import i18next from 'i18next';
import styles from './index.module.less';

export interface IDialog {
  showMask?: boolean;
  // children?: React.ReactElement;
  // canCloseByTouchMask?: boolean;
  title?: string | JSX.Element;
  content: string | (string | JSX.Element)[];
  confirmBtnText?: string;
  hideCancelBtn?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const DialogElement = ({ showMask, title, content, confirmBtnText, hideCancelBtn, onConfirm, onCancel }: IDialog) => {
  const { isPc } = detectBrowser();
  const titleStyle = classNames(styles.dialog_title, isPc ? styles.dialog_title_pc : styles.dialog_title_h5);
  const containerStyle = classNames(styles.dialog_container, isPc ? styles.dialog_container_pc : styles.dialog_container_h5);
  const contentStyle = styles.dialog_content;

  return (
    <div className={styles.dialog}>
      {showMask ? <div className={styles.dialog_mask}></div> : null}
      <div className={containerStyle}>
        {title ? <p className={titleStyle}>{title}</p> : null}
        <p className={contentStyle}>{content}</p>
        <button className={styles.dialog_confirm_btn} onClick={() => onConfirm?.()}>
          {confirmBtnText}
        </button>
        {!hideCancelBtn ? (
          <button onClick={() => onCancel?.()} className={styles.dialog_cancel_btn}>
            Cancel
          </button>
        ) : null}
      </div>
    </div>
  );
};

export enum EToastType {
  SUCCESS = 1,
  ERROR = 2,
  INFO = 3,
}

class Dialog {
  showMask?: boolean;
  content: string | (string | JSX.Element)[];
  confirmBtnText: string;
  title?: string | JSX.Element;
  hideCancelBtn?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  dialogElement?: HTMLElement | null = null;

  constructor({ showMask, content, confirmBtnText, onConfirm, hideCancelBtn, title, onCancel }: IDialog) {
    this.showMask = showMask || true;
    this.title = title;
    this.content = content;
    this.confirmBtnText = confirmBtnText || i18next.t('btn_got_it_h5');
    this.onConfirm = onConfirm || (() => this.close());
    this.onCancel = onCancel;
    this.hideCancelBtn = hideCancelBtn || true;
    this.dialogElement = null;
    this.createDialog();
  }

  createDialog() {
    this.dialogElement = document.createElement('div');
    document.body.appendChild(this.dialogElement);
    const dialogRoot = createRoot(this.dialogElement);
    dialogRoot.render(
      <DialogElement
        showMask={this.showMask}
        title={this.title}
        content={this.content}
        confirmBtnText={this.confirmBtnText}
        hideCancelBtn={this.hideCancelBtn}
        onConfirm={this.onConfirm}
        onCancel={this.onCancel}
      />,
    );
  }

  close() {
    if (this.dialogElement) {
      document.body.removeChild(this.dialogElement);
      this.dialogElement = null;
    }
  }
}

export const showDialogue = (options: IDialog) => {
  return new Dialog(options);
};
