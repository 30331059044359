import { initNewSapForThirdParty, initDfpForBank } from '@shopee/secure-fetch-utils';
import { recordPerformance } from '@banking/web-sdk/utils/performance';
import { fetchWithoutSapDfp, getApiHost } from '../services';

const DFP_STORE_KEY = 'dfp';

export function setDfp(dfp: string) {
  window.sessionStorage.setItem(DFP_STORE_KEY, dfp);
}

async function initDfp() {
  const { data, setResponseText } = await initDfpForBank({
    enableAntiDebug: process.env.NODE_ENV === 'production',
  });

  const result = await fetchWithoutSapDfp({
    url: `${getApiHost()}/h5/device-security/dfp/v1/data/web/report`,
    params: {
      method: 'POST',
      body: {
        inputData: data,
      },
    },
  });

  setResponseText?.(result?.data?.riskToken);
  setDfp(result?.data?.riskToken);

  const originalFetch = window.fetch;
  window.fetch = function (url, options) {
    const headers = new Headers(options?.headers || {});
    headers.append('Sz-Token', result?.data?.riskToken);
    const newOptions = Object.assign({}, options, {
      headers: headers,
    });
    return originalFetch.call(this, url, newOptions);
  };
}

async function initSecureFetch() {
  return new Promise((resolve, reject) => {
    recordPerformance({
      type: 'init-dfp',
      startTimestamp: new Date().valueOf(),
    });
    initDfp()
      .then(() => {
        recordPerformance({
          type: 'init-dfp',
          endTimestamp: new Date().valueOf(),
        });
        recordPerformance({
          type: 'init-sap',
          startTimestamp: new Date().valueOf(),
        });
        initNewSapForThirdParty({ allowCors: true }).then(() => {
          recordPerformance({
            type: 'init-sap',
            endTimestamp: new Date().valueOf(),
          });
          resolve('init dfp and sap successfully');
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function getDfp() {
  const dfp = window.sessionStorage.getItem(DFP_STORE_KEY);
  if (!dfp) {
    await initSecureFetch();
  }
  return dfp;
}

export default initSecureFetch();
