export enum EErrorCode {
  PIN_INCORRECT_OTHER = 4010096,
  PIN_INCORRECT_REMAIN_ZERO = 4100007,
  NO_SOFTTOKEN_ACTIVE_DEVICE = 4022031,
  LOGIN_REJECTED = 4010042,
}

export enum EFetchErrorCode {
  Unauthorized = 401,
  InvalidateSign = 3000008,
  InvalidateSign2 = 3000009,
}

export const RSA_PUBLIC_KEY_ERROR = [1000003, 3000005, 3000006, 3000007];
